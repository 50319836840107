import React from "react";
import { useSelector } from "react-redux";
import Select from "./Select";
import Counter from "../../components/Counter/Counter";

const SfcFormOne = ({ formValues = {}, onChangeHandler}) => {

  const {isLoading, cities, territory, transport, categories} = useSelector(
    (state) => state.sfcFormFilters
  );


  return (
    <div className="max-w-2xl mx-auto">
      <div className="text-secondary-400 text-fieldset">Journey</div>
      <div className="w-full h-0.5 bg-gray-200 my-3"></div>
      <div className=" flex flex-col gap-4 ">
        <Select
          options={territory}
          placeholder={"From Territory"}
          labelAttri={"territorY_Name"}
          valueAttri={"territorY_ID"}
          onChange={onChangeHandler}
          value={formValues.FromTerritory}
          name="FromTerritory"
        />
        <Select
          disabled={!formValues.FromTerritory}
          options={territory}
          placeholder={"To Territory"}
          labelAttri={"territorY_Name"}
          valueAttri={"territorY_ID"}
          onChange={onChangeHandler}
          value={formValues.ToTerritory}
          name={"ToTerritory"}
        />
        {/* <Select options={SfcTerritory?.data || []}  className={"h-10 border border-gray mt-4"}/>
        <Select options={SfcTransport?.data || []}  className={"w-[350px] h-10 border border-gray mt-4"}/> */}
        <Select
          options={cities}
          placeholder={"From City"}
          labelAttri={"cityName"}
          valueAttri={"cityId"}
          onChange={onChangeHandler}
          value={formValues.FromCity}
          name={"FromCity"}
        />
        <Select
          disabled={!formValues.FromCity}
          options={cities}
          placeholder={"To City"}
          labelAttri={"cityName"}
          valueAttri={"cityId"}
          onChange={onChangeHandler}
          value={formValues.ToCity}
          name={"ToCity"}
        />
        <div className="flex gap-5">
          <Select
            options={categories}
            placeholder={"Category"}
            labelAttri={"city_Category"}
            valueAttri={"cityCategory_id"}
            onChange={onChangeHandler}
            value={formValues.CategoryId}
            name={"CategoryId"}
          />
          <Select
            options={transport}
            placeholder={"Transport Mode"}
            labelAttri={"transport_name"}
            valueAttri={"transport_id"}
            onChange={onChangeHandler}
            value={formValues.TransportMode}
            name={"TransportMode"}
          />
        </div>
        <div className="flex justify-between mt-5 p-5">
          <div>
            <label htmlFor="no of documents">No of Doctors</label>
            <Counter value={formValues.noOfDoctors} min={0} onChange={(action) => onChangeHandler({target: {name:"noOfDoctors",value: action.value}})} />
          </div>
          <div>
            <label htmlFor="no of Chemist">No of Chemist</label>
            <Counter value={formValues.noOfChemists} min={0} onChange={(action) => onChangeHandler({target: {name:"noOfChemists",value: action.value}})}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SfcFormOne;
