import React, { useState } from "react";

const Select = ({ options=[] ,value, valueAttri, onChange, placeholder, labelAttri, className = '', label, name, ...restProps}) => {
  const [enable, setEnable] = useState(false);

  return (
     <select
       className={"w-full border border-gray-600 disabled:border-gray-400 rounded-sm text-gray-500 disabled:text-gray-400 disabled:cursor-not-allowed p-3"+ className} onChange={onChange} name={name} value={value} {...restProps}>
      <option value={""}>{placeholder}</option>
     {
      options?.map((opt, index) => {
        return <option value={opt[valueAttri]} key={index}>{opt[labelAttri]}</option>
      })
     }
     </select>
  );
};

export default Select;
