import React, { useEffect, useState } from 'react';
import { getAllUnreportedExpense } from '../../api/cameraApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
const UnReportedExpenses = () => {
  const [unReportedList, setUnReportedList] = useState([]);

  // const expenseDetails = useSelector((state) => state.camera.unReportedList);

  const getUnreportedExpenses = async () => {
    let res = await getAllUnreportedExpense();
    console.log(' unreported Component res', res);
    if (res) {
      setUnReportedList(res.expenseUploadTempList);
    }
  };

  useEffect(() => {
    getUnreportedExpenses();
  }, []);

  return (
    <>
      {/* {unReportedList && unReportedList.length > 0 && ( */}
      <div className="p-4 relative w-screen h-screen">
        <div className="flex justify-around items-center mb-4">
          <div className=''>
            <h1 className="text-2xl font-bold text-center">
              Unreported Expenses
            </h1>
          </div>
          {/* <input className="rounded-md" type="radio" /> */}

        <div>
          <Link to={'/'}>
            <FontAwesomeIcon icon="fa-solid fa-x"  className='text-xl' color='gray'/>
          </Link>
        </div>
        </div>
        {/* <div className='flex flex-col'> */}
        {unReportedList.length > 0 && unReportedList.map((item, i) => (
          <div
            key={i}
            className="mt-4 bg-gray-200 rounded-md flex justify-between p-4 "
          >
            <div className=" flex items-center">
              {/* <input type="radio" className="mr-2" /> */}
              <img
                src={`data:image/jpg;base64,${item.imageBase64}`}
                alt="Unrecognized"
                className="w-24 h-24 object-cover rounded-md"
              />
            </div>
            <div className="w-full p-4">
              <div className="flex flex-row justify-between">
                <p className="font-semibold">{item.invoiceDate.slice(0, 10)}</p>
                <p className="font-semibold">{item.status || 'Pending'}</p>
              </div>
              <div className="flex flex-row justify-between">
                <p className="font-semibold text-2xl">
                  {item.invoiceNumber || 'N/A'}
                </p>
                <p className="font-semibold text-2xl">{item.amount || 'N/A'}</p>
              </div>
              <div className="flex flex-row justify-between">
                <p className="font-semibold">{item.expenseType || 'N/A'}</p>
                <p className="font-semibold">Delete</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* )} */}
    </>
  );
};

export default UnReportedExpenses;
