import { createSlice } from '@reduxjs/toolkit'
import { getExpenseType } from '../../api/cameraApi'

const initialState = {
  image: '',
  imageFile: '',
  cropImage: '',
  currentImage: '',
  expenseType:[],
  unReportedList:[],
  file: null
}

export const cameraSlice = createSlice({
  name: 'camera',
  initialState,
  reducers: {
    captureImage: (state,{payload}) => {
      state.image = payload
    },
    captureImageFile: (state,{payload}) => {
      state.imageFile = payload
    },
    captureCropImage: (state,{payload}) => {
      console.log("Crop  image Redux",payload);
      state.cropImage = payload
    },
    currentImage: (state,{payload}) => {
      state.currentImage = payload
    },
    expenseType: (state,{payload}) => {
      state.expenseType = payload
    },
    unReportDetails: (state,{payload}) => {
      state.unReportedList = payload
    },
    setFileCapture: (state,{payload}) => {
      state.file = payload
    }
  },
})

export const { captureImage ,
  captureImageFile,captureCropImage,currentImage,
  expenseType,unReportDetailsm, setFileCapture,unReportDetails} = cameraSlice.actions



export default cameraSlice.reducer