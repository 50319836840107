import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Plane, ViceversaArrow } from "./compoments/Icons";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";


const CardContainer = ({ status, setDisplayData, val }) => {
  const [display, setDisplay] = useState(false);

  return (
    <div className="p-3 bg-white">
      <div onClick={() => setDisplay((prev) => !prev)}>
        <div className="flex justify-between">
          <div className="flex gap-5">
            <div>
              <Plane />
            </div>
            <div className="text-secondary">{val.transporT_TYPE}</div>
            <div className="text-secondary">HQ</div>
          </div>
          <div
            className={`text-${
              status === "A" ? "primary" : "draft"
            } bg-${status === "A" ? null : "draft"} px-3 py-0.5 rounded`}
          >
            {status === "A" ? "Approved" : status === "D" ? "Draft" : null}
          </div>
        </div>
        <div className="flex justify-between p-1">
          <div className="flex md:gap-40 my-lg:gap-7">
            <div>{val?.fromCity}</div>
            <div>
              <ViceversaArrow />
            </div>
            <div>{val?.toCity}</div>
          </div>
          <div>
            <span className="text-primary">INR</span> 2400
          </div>
        </div>
      </div>
      <div className={`${display ? "show" : "hidden"}`}>
        {/* <div className="w-1/1 h-0.5 bg-gray-200 mt-2 "></div> */}
        <div className="flex justify-between m-2 p-1">
          <div>Category</div>
          <div>{val?.category}</div>
        </div>
        {/* <div className="w-1/1 h-0.5 bg-gray-200 mt-4 "></div> */}
        <div>
          <div className="flex justify-between text-gray-500 m-2 p-1">
            <div>One Way Distance</div>
            <div>{val?.distancE_KMS_ONEWAY}</div>
          </div>
          <div className="flex justify-between text-gray-500 m-2 p-1">
            <div>Two Way Distance</div>
            <div>{val?.distancE_KMS_TWOWAY}</div>
          </div>
        </div>
        <div className="w-1/1 h-0.5 bg-gray-200 mt-4 "></div>
        <div>
          <div className="flex justify-between text-gray-500 m-2 p-1">
            <div>One way Fare</div>
            <div>{val?.farE_ONEWAY}</div>
          </div>
          <div className="flex justify-between text-gray-500 m-2 p-1">
            <div>Two way Fare</div>
            <div>{val?.farE_TWOWAY}</div>
          </div>
          <div className="flex justify-between text-gray-500 m-2 p-1">
            <div>Allowance</div>
            <div>{val?.dailY_ALLOWANCE}</div>
          </div>
          <div className="flex justify-between bold m-2 p-1">
            <div>Total</div>
            <div>{val?.
total}</div>
          </div>
        </div>
      </div>
      <div className="flex justify-between ">
        {status === "DRAFT" && display ? (
          <div>
            <button>
              <FontAwesomeIcon
                icon={faPenToSquare}
                className="text-primary m-2 p-1"
              />
            </button>
          </div>
        ) : (
          <div></div>
        )}
        <div>
          <button
            onClick={() =>
              setDisplayData((preValues) => [
                ...preValues.filter((obj) => obj.empId !== val.empId),
              ])
            }
          >
            <FontAwesomeIcon icon={faTrash} className="text-primary m-2 p-1" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardContainer;
