import React from "react";

const Plane = ({ size = "24px", color = "#A04D95" }) => {
    return <svg width={size} height={size} viewBox="0 0 24px 24px" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="majesticons:airplane">
    <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M10.2999 8.75H12.8124C13.2268 8.75 13.6243 8.58538 13.9173 8.29235C14.2103 7.99933 14.3749 7.6019 14.3749 7.1875C14.3749 6.7731 14.2103 6.37567 13.9173 6.08265C13.6243 5.78962 13.2268 5.625 12.8124 5.625H4.13619C4.02015 5.62494 3.90642 5.59257 3.80774 5.53153C3.70905 5.47049 3.62931 5.38318 3.57744 5.27937L3.29744 4.72062C3.24557 4.61682 3.16583 4.52951 3.06714 4.46847C2.96846 4.40743 2.85473 4.37506 2.73869 4.375H1.57307C1.46959 4.37491 1.36771 4.40051 1.27656 4.44951C1.18542 4.49851 1.10787 4.56937 1.05087 4.65573C0.993874 4.7421 0.959211 4.84126 0.949994 4.94433C0.940777 5.0474 0.957296 5.15114 0.998066 5.24625L2.33744 8.37125C2.38567 8.48375 2.46586 8.57961 2.56808 8.64694C2.6703 8.71427 2.79004 8.75011 2.91244 8.75H5.32682C5.4289 8.75006 5.52941 8.77513 5.61957 8.82301C5.70972 8.87089 5.78678 8.94013 5.844 9.02466C5.90122 9.1092 5.93686 9.20646 5.94781 9.30796C5.95876 9.40945 5.94469 9.51208 5.90682 9.60688L5.34369 11.0187C5.30597 11.1135 5.29199 11.216 5.30297 11.3173C5.31395 11.4187 5.34957 11.5158 5.4067 11.6003C5.46383 11.6847 5.54076 11.7539 5.63076 11.8018C5.72076 11.8496 5.82112 11.8748 5.92307 11.875H7.19932C7.29305 11.875 7.38558 11.854 7.47008 11.8134C7.55457 11.7728 7.62886 11.7138 7.68744 11.6406L9.81244 8.98438C9.87096 8.91129 9.94514 8.85228 10.0295 8.81172C10.1139 8.77115 10.2063 8.75006 10.2999 8.75ZM5.93744 5H8.90619L7.68744 3.375C7.62922 3.29738 7.55373 3.23438 7.46695 3.19098C7.38016 3.14759 7.28447 3.125 7.18744 3.125H6.01119C5.9047 3.12506 5.79998 3.15232 5.70699 3.20422C5.61399 3.25611 5.53579 3.3309 5.47982 3.4215C5.42385 3.51209 5.39195 3.61549 5.38716 3.72188C5.38237 3.82827 5.40484 3.93411 5.45244 4.02938L5.93744 5Z" fill={color}/>
    </g>
    </svg>

};

export default Plane;