import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ExpenseList = ({key}) => {
    return (
        <div className="w-full  bg-white rounded-md p-2" key={key}>
        <div className="w-full flex justify-between align-items-center">
          <div className="p-1">
            <p>
            <FontAwesomeIcon icon="fa-solid fa-car-side" color='#A04D95' /> <span style={{color:'#79747E'}}>Own Car</span> <span style={{color:'#79747E'}}>HQ</span>
            </p>
          </div>
          <div className="">
            <p style={{color:'#A04D95'}} className='text-'>Submitted</p>
          </div>
        </div>
        <div className="w-full h-10 flex ">
          <div className="w-full flex justify-between">
            <div className="flex">
              <h1 className='text-xl'>Mumbai</h1>
              <div className='pl-4 pr-4 pt-1'>
              <FontAwesomeIcon icon="fa-solid fa-right-left" size='xl'  style={{color:'#49454F'}}/>
              </div>
              <h1 className='text-xl'>Mumbai</h1>
            </div>
            <div>
              <p>INR 450.00</p>
            </div>
          </div>
        </div>
        <div className="w-full h-6 flex justify-between">
          <div>
            <p style={{color:'#79747E'}}>Field Work</p>
          </div>
          <div>
            <p style={{color:'#2FC23D'}} className='text-sm'>Sun,2nd June 2024</p>
          </div>
        </div>
      </div>
    );
}

export default ExpenseList;
