import React, { useState } from 'react';
import ExpenseList from './MyExpenseList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MyExpense = () => {
  const [loopTime, setLoopTime] = useState(20);

  return (
    <div className="w-screen h-screen flex flex-col p-4">
      {/* Header */}
      <div className="flex flex-row w-full h-10 justify-center items-center">
        <h3 className="absolute left-4"><FontAwesomeIcon icon="fa-solid fa-angle-left"  size='lg' color='#A04D95' /></h3>
        <h1 style={{ color:'#A04D95', fontWeight:'bold'}} className='text-2xl '>My Expense</h1>
      </div>

      {/* Button Container */}
      <div className="flex flex-row w-full h-15 justify-center items-center mt-2 flex-nowrap overflow-x-auto">
        {/* <div className=""> */}
          <button className='rounded-xl p-2 m-1 border-2 ml-10'>Unsubmitted</button>
        {/* </div> */}
        {/* <div className="rounded-md p-2 border-1"> */}
          <button className='rounded-xl p-2 m-1 border-2'>Submitted</button>
        {/* </div> */}
        {/* <div className="rounded-md p-2 border-1"> */}
          <button className='rounded-xl p-2 m-1 border-2'>Mumbai</button>
        {/* </div> */}
        {/* <div className="rounded-md p-2 border-1"> */}
          <button className='rounded-xl p-2 m-1 border-2'>Bangalore</button>
        {/* </div> */}
      </div>
      <div className="divider-y h-1 w-full bg-gray-300 mt-2"></div>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto mt-2 " style={{ height: '80vh' }}>
        {[...Array(loopTime)].map((_, index) => (
          <div>
            <ExpenseList key={index} />
            <div className="divider-y h-1 w-full bg-gray-300"></div>
          </div>
        ))}
      </div>


      {/* Fixed Submit Button */}
      <div
        className="flex justify-center items-center rounded-full mb-12" style={{ background: '#A04D95', height: '6vh' }}
      >
        <button className=" text-white fixed">
          Submit
        </button>
      </div>

    </div>
  );
};

export default MyExpense;
