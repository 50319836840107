import React from "react";
const ViceversaArrow = ({ size = "24px", color = "#878787"}) => {
    return <svg width={size} height={size} viewBox="0 0 24px 24px" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="tdesign:swap">
    <path id="Vector" d="M14.9999 3.086L22.4139 10.5H1.99994V8.5H17.5859L13.5859 4.5L14.9999 3.086ZM21.9999 13.5V15.5H6.41394L10.4139 19.5L8.99994 20.914L1.58594 13.5H21.9999Z" fill={color}/>
    </g>
    </svg>

};

export default ViceversaArrow;