import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getExpenseType ,expenseSubmit} from '../../api/cameraApi';
import {unReportDetails} from '../../redux/slice/cameraSlice';
import { useNavigate } from 'react-router-dom';

const CapturedDetails = () => {
  const [dropdownItems, setDropdownItems] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const [loadong, setLoading] = useState(false);


  const navigate = useNavigate();

  let CurrentImage = useSelector((state) => state.camera.currentImage);
  const [invoiceDetails, setInvoiceDetails] = useState({
    todayDate: new Date().toISOString().split('T')[0],
    total: '1000',
    desc: '',
    invoiceNo: '9',
    expenseType: '',

  });

  const dispatch = useDispatch();

  const getExpense = async () => {
    try {
      const response = await getExpenseType();
      setDropdownItems(response);
    } catch (error) {
      console.error('Error fetching expense type:', error);
      return null;
    }
  };

  useEffect(() => {
    getExpense();
  }, []);


  const handlerEditInvoice = () => {
    setEdit(!isEdit);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoiceDetails({
      ...invoiceDetails,
      [name]: value,
    });
  };


  const handleSaveClick = async() => {
    const EmployeeId =8271 ;
    const CompanyId =1;
    const { todayDate: UploadDate, total:Amount, desc, invoiceNo:InvoiceNumber, expenseType:ExpenseType } ={...invoiceDetails};
    let newData = {
      UploadDate,
      Amount,
      desc,
      InvoiceNumber,
      ExpenseType,
      EmployeeId,
      CompanyId,
      ImageBase64:CurrentImage,
      InvoiceDate:UploadDate
    }
   let res = await expenseSubmit(newData)
   if(res){
    navigate('/un-reported-expenses')
   }
  };



  return (
    dropdownItems  && (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="p-4 rounded-lg shadow-md w-full md:w-3/4 lg:w-1/2 flex flex-col space-y-4">
        <div className="w-full flex justify-center items-center">
          <img
            src={CurrentImage}
            alt="Placeholder"
            className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg h-auto rounded-lg"
            style={{ width: '13rem', height: '15rem' }}
          />
        </div>
        <div className='flex justify-end'>
        <button onClick={handlerEditInvoice} className="bg-blue-500 text-white px-4 py-2 rounded" >
          {isEdit ? 'Save' : 'Edit'}

        </button>
        </div>

        <div className="max-w-sm mx-auto relative flex">
          <label
            htmlFor="expense-type"
            className="block text-lg font-medium text-gray-700 mb-2"
          >
            Expense Type:
          </label>
          <select
            id="expense-type"
            name="expenseType"
            className="block w-full border border-gray-300 rounded-lg bg-white shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm custom-dropdown"
            onChange={handleInputChange}
            value={invoiceDetails.expenseType}
          >
            <option value="">Select an option</option>
            {dropdownItems.length > 0 &&
              dropdownItems.map((option) => (
                <option
                  key={option.expense_Type_Id}
                  value={option.expense_Type}
                  className="w-40"
                >
                  {option.expense_Type}
                </option>
              ))}
          </select>
        </div>

        {/* Invoice Details */}
        <div className="w-full flex flex-col space-y-4">
          <div className="flex items-center">
            <label className="w-1/3 text-gray-700 text-sm font-bold">
              Invoice Date
            </label>
            <input
              type="date"
              name="todayDate"
              value={invoiceDetails.todayDate}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-2/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              readOnly={!isEdit}
            />
          </div>
          <div className="flex items-center">
            <label className="w-1/3 text-gray-700 text-sm font-bold">
              Invoice Number
            </label>
            <input
              type="text"
              name="invoiceNo"
              value={invoiceDetails.invoiceNo}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-2/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              readOnly={!isEdit}
            />
          </div>
          <div className="flex items-center">
            <label className="w-1/3 text-gray-700 text-sm font-bold">
              Total
            </label>
            <input
              type="text"
              name="total"
              value={invoiceDetails.total}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-2/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              readOnly={!isEdit}
            />
          </div>
        </div>

        {/* Description */}
        <div className="w-full flex-grow">
          {/* <label className="block text-gray-700 text-sm font-bold mb-2">
            Description
          </label> */}
          <textarea
            name="desc"
            value={invoiceDetails.desc}
            onChange={handleInputChange}
            placeholder="Enter description here..."
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-24"
            // readOnly={!isEdit}
          />
        </div>

        {/* Buttons */}
        {/* <div className="w-full flex justify-around space-x-4">
          <button className="bg-gray-500 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Cancel
          </button>
          <Link to="/un-reported-expenses">
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={handleSaveClick}
            >
              Save
            </button>
          </Link>
        </div> */}

        <div className=" bg-white  border-gray-200 flex justify-between">
        <Link to="/camera">
          <button className="py-4 px-14  bg-white border border-red-400 rounded-lg">
           <p className='text-xl fontSem-bold' style={{color: '#A04D95', fontWeight: '600'}}>Cancel</p>
          </button>
        </Link>
        <Link to="/un-reported-expenses">
          <button className=" text-white  py-4 px-14  rounded-lg" style={{ backgroundColor: '#A04D95' }}
           onClick={handleSaveClick}
          >
            <p className='text-white text-xl fontSem-bold'>Save</p>
          </button>
        </Link>
      </div>
      </div>
    </div>
        )
  );
};

export default CapturedDetails;
