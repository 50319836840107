

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseURL = "https://keaexpenseapi.kea-sfe.com/api/ExpenseIdCreation";
// const baseURL = "http://192.168.1.243:1105/api/ExpenseIdCreation";


const defaultParams = {
    Status: "A",
    EmployeeId: 321,
    STPFCId: 23456,
    EffectiveDate: "2024-01-01",
    IsFareAuto: 0,
    CompanyId: 1
}
export const postSfcSaveTravelExpence = createAsyncThunk("postSfcSaveTravelExpence", async (data) => {
    return axios.post(`${baseURL}/SFCAdd`,null,{ params: {...data, ...defaultParams}})

});

export const sfcSaveTravelExpence = createSlice({
    name: "sfcSaveTravelExpence",
    initialState: {
        isLoading: false,
        data: null,
        apiError: ""
    },
    extraReducers: (builder) => {
        builder.addCase(postSfcSaveTravelExpence.pending , (state) => {
            state.isLoading = true
        })

        builder.addCase(postSfcSaveTravelExpence.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload.data;
        })
        builder.addCase(postSfcSaveTravelExpence.rejected, (state) => {
            state.apiError = "Something went wrong"
            state.isLoading = false
            state.data = null
        })
    }
})

export default sfcSaveTravelExpence.reducer;