import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseURL = "https://keaexpenseapi.kea-sfe.com/api/ExpenseIdCreation";
// const baseURL = "http://192.168.1.243:1105/api/ExpenseIdCreation";

export const fetchSfcFormFilters = createAsyncThunk("fetchSfcFormFilters", async () => {
   return Promise.all([
    axios.get(
      `${baseURL}/SFCTerritoryCity?TerritoryId=946`
    ),
    axios.get(
      `${baseURL}/SFCTerritoryCategory`
    ),
    axios.get(
      `${baseURL}/SFCTerritoryModeOfTransport?gradeId=1&type=expense`
    ),
    axios.get(`${baseURL}/SFCTerritory?employeeId=8271&companyId=25`)
  ]);
});







export const sfcFormFilters = createSlice({
  name: "sfcFormFilters",
  initialState: {
    isLoading: false,
    cities: [],
    territory: [],
    transport: [],
    categories: [],
    apiError: "",
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSfcFormFilters.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchSfcFormFilters.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cities = action.payload[0].data;
      state.categories = action.payload[1].data;
      state.transport = action.payload[2].data;
      state.territory = action.payload[3].data;
    });
    builder.addCase(fetchSfcFormFilters.rejected, (state) => {
      state.apiError = "Something went wrong";
      state.isLoading = false;
      state.cities = [];
      state.categories = [];
      state.transport = [];
      state.territory = [];
    });
  },
});

export default sfcFormFilters.reducer;
