//

import React from 'react';
// import Nav from '../../components/nav/Nav';
// import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

const Home = () => {
  return (
    <>
      <div
        className="p-4 pt-8"
        style={{ height: '90vh', width: '100vw' }}
      >
        {/* Expense Dashoard */}
        <div className="bg-blue-400 h-40 w-full  rounded-md p-4 flex flex-col justify-between text-left">
          <h6 className='text-yellow-400 text-xl'>Expenses</h6>
          <h1 className='text-3xl text-white bold  '>INR 83000</h1>
          <p className='text-white'>Current Month Expense</p>
        </div>

        {/* All Expense */}
        <div className="flex justify-between mt-8">
          <h6 className="text-2xl font-bold text-center">All Expense</h6>
          <button className="bg-gray-200 px-4 py-2 rounded-md text-pink-500">
            View More
          </button>
        </div>
        <div className="flex mt-3 justify-between bg-gray-200 p-4 rounded-md">
          <p>Previous month</p>
          <div>
            <p className="text-sm">Approved</p>
            <p>INR 8830</p>
          </div>
        </div>
        <div className="flex mt-3 justify-between bg-gray-200 p-4 rounded-md">
          <p>June - 2024</p>
          <div>
            <p className="text-sm">Approved</p>
            <p>INR 8830</p>
          </div>
        </div>

        {/* Bill */}
        <div className="flex justify-between mt-8">
          <h6 className="text-2xl font-bold text-center">Bill</h6>
          <button className="bg-gray-200 px-4 py-2 rounded-md text-pink-500">
            View More
          </button>
        </div>
        <div className="flex mt-3 justify-between bg-gray-200 p-4 rounded-md">
          <p>Travel</p>
          <div>
            {/* <p className='text-sm'>Approved</p> */}
            <p>INR 8830</p>
          </div>
        </div>
        <div className="flex mt-3 justify-between bg-gray-200 p-4 rounded-md">
          <p>Food</p>
          <div>
            {/* <p className='text-sm'>Approved</p> */}
            <p>INR 8830</p>
          </div>
        </div>
        <div className="flex mt-3 justify-between bg-gray-200 p-4  rounded-md">
          <p>Miscellaneous</p>
          <div>
            {/* <p className='text-sm'>Approved</p> */}
            <p>INR 8830</p>
          </div>
        </div>
        <div className="flex mt-3 justify-between bg-gray-200 p-4  rounded-md">
          <p>Miscellaneous</p>
          <div>
            {/* <p className='text-sm'>Approved</p> */}
            <p>INR 8830</p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
