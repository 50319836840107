import axios from 'axios';
import Swal from 'sweetalert2';





// const baseUrl = 'http://4.240.71.187/keaexpenseapi/api/ExpenseIdCreation'
const baseUrl ='https://keaexpenseapi.kea-sfe.com/api/ExpenseIdCreation'

// const baseURLOld =http://192.168.1.243:1105/api/ExpenseIdCreation/ExpenseType
// https://keaexpenseapi.kea-sfe.com/api/ExpenseIdCreation/GetExpenseUploadTemp?employeeId=8271&companyId=1
//upload expense file
export  async function uploadExpenseFile(imageSrc) {
    try {
      const EmployeeId = 8271;
      const Date = 12;
      const Month = 10;
      const Year = 2027;
      const Type = 'Expense By Md Farhan';

      const formData = {
        ImageBase64: imageSrc,
        EmployeeId,
        Date,
        Month,
        Year,
        Type,
      };

      const uploadResponse = await axios.post(
        `${baseUrl}/ExpenseFileUpload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },

        }
      );
      console.log('File upload response:', uploadResponse);

      if (uploadResponse.status === 200) {
        console.log('File uploaded successfully!');
        Swal.fire({
          icon: 'success',
          title: 'File uploaded successfully!',
          showConfirmButton: false,
          timer: 1500,
        });
        return uploadResponse;

      } else {
        console.log('File upload failed with status:', uploadResponse.status);
        Swal.fire({
          icon: 'error',
          title: 'File upload failed!',
          showConfirmButton: false,
          timer: 1500,
        });

        return uploadResponse;
      }
    } catch (error) {
      if (error.response) {
        console.error('Error response data:', error.response.data);
        Swal.fire({
          icon: 'error',
          title: 'Error While Uploadig Image',
          showConfirmButton: false,
          timer: 1500,
        });
      }
      console.log('An error occurred while uploading the file.');
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while uploading the file.',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }





  //get all expense Type

  export const getExpenseType = async () => {
    try {
      const response = await axios.get(`${baseUrl}/ExpenseType`);
      console.log('Response: APIIIIIIIIII', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching expense type:', error);
      return null;
    }
  }



  //Expense Submit button api

  export const expenseSubmit = async (data) => {
    try {
      console.log('data Camera API', data);
      const uploadResponse = await axios.post(
        `${baseUrl}/ExpenseUploadTemp`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },

        }
      );
      // console.log('File upload response:', uploadResponse);
      return uploadResponse.data;
    } catch (error) {
      console.error('Error fetching expense type:', error);
      return null;
    }
  }



  //get all expense Type
  export const getAllUnreportedExpense = async () => {
    try {
      const response = await axios.get(`${baseUrl}/GetExpenseUploadTemp?employeeId=8271&companyId=1`);
      console.log('Response: APIIIIIIIIII', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching expense type:', error);
      return null;
    }
  }

