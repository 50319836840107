import React from "react";


const SfcFormTwo = ({ onChangeHandler, formValues, disableOneway}) => {
  return (
    <div className="max-w-2xl mx-auto">
      <div className="text-secondary-400 text-fieldset">Distance</div>
      <div className="w-1/1 h-0.5 bg-slt-color mt-2"></div>
      <div className="grid grid-cols-2 gap-5">
        <input type="text" placeholder="One Way" className="h-14 border border-gray-600 mt-4 rounded-md text-gray-500 pl-3 disabled:border-gray-400 disabled:text-gray-400" name="OneWayDistance" value={formValues?.OneWayDistance} onChange={onChangeHandler} disabled={disableOneway}/>
        <input type="text" placeholder="Two Way" className="h-14 border border-gray-400 mt-4 rounded-md text-gray-400 pl-3" value={formValues?.TwoWayDistance} disabled/>
      </div>
      <div className="mt-5">
        <div className="text-secondary-400 text-fieldset">Fare & Allowance</div>
        <div className="w-1/1 h-0.5 bg-slt-color mt-2"></div>
        <div className="grid grid-cols-2 gap-5">
           <input type="text" placeholder="One Way Fare" className="h-14 border border-gray-400 mt-4 rounded-md text-gray-400 pl-3" value={formValues?.OneWayFare} disabled/>
           <input type="text" placeholder="Two Way Fare" className="h-14 border border-gray-400 mt-4 rounded-md text-gray-400 pl-3" value={formValues?.TwoWayFare} disabled/>
           <input type="text" placeholder="Allowance" className="h-14 border border-gray-400 mt-4 rounded-md text-gray-400 pl-3" value={formValues?.DailyAllowance} disabled/>
           <input type="text" placeholder="Total" className="h-14 border border-gray-400 mt-4 rounded-md text-gray-400 pl-3" value={formValues?.Total} disabled/>
        </div>
      </div>
    </div>
  );
};

export default SfcFormTwo;
