import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine ,faHouse,faHandHoldingDollar ,faSquarePhoneFlip,faGear,faPowerOff, faUser, faHome, faBell, faCamera, faXmark} from '@fortawesome/free-solid-svg-icons';


<FontAwesomeIcon icon={faPowerOff} />

import {
    Home,
    Sfc,
    MyExpense,
    Dcr,
    User,
    Setting,
    Logout,
    Callplan,
    CameraOption,
    Notification,
    CapturedImage,
    CapturedDetails,
    UnReportedExpenses,
    CropImage,
    PageNotFound


} from '../pages/route';


export const routersConfig = [
    { path: '/', element: <Home />,icon:<FontAwesomeIcon icon={faHouse} /> },
    { path: '/home', element: <Home />,icon:<FontAwesomeIcon icon={faHome} /> },
    { path: '/sfc', element: <Sfc />,icon:<FontAwesomeIcon icon={faChartLine} /> },
    { path: '/my-expense', element: <MyExpense />,icon:<FontAwesomeIcon icon={faHandHoldingDollar} /> },
    { path: '/dcr', element: <Dcr />,icon:<FontAwesomeIcon icon={faChartLine} />},
    { path: '/user', element: <User />,icon:<FontAwesomeIcon icon={faUser} />},
    { path: '/setting', element: <Setting />,icon:<FontAwesomeIcon icon={faGear} />},
    { path: '/logout', element: <Logout />,icon:<FontAwesomeIcon icon={faPowerOff} /> },
    { path: '/callplan', element: <Callplan />,icon:<FontAwesomeIcon icon={faSquarePhoneFlip} /> },
    { path: '/camera', element: <CameraOption />,icon:<FontAwesomeIcon icon={faCamera} /> },
    { path: '/notification', element: <Notification />,icon:<FontAwesomeIcon icon={faBell} /> },
    { path: '/capture-image', element: <CapturedImage />,icon:<FontAwesomeIcon icon={faBell} /> },
    { path: '/captured-image-details', element: <CapturedDetails />,icon:<FontAwesomeIcon icon={faBell} /> },
    { path: '/crop-image', element: <CropImage />,icon:<FontAwesomeIcon icon={faBell} /> },
    { path: '/un-reported-expenses', element: <UnReportedExpenses />,icon:<FontAwesomeIcon icon={faBell} /> },
    { path: '*', element: <PageNotFound />,icon:<FontAwesomeIcon icon={faXmark} /> }
];

//side bar

export const sideBarConfig = [
    { path: '/', element: <Home />,icon:<FontAwesomeIcon icon={faHome} />,name:'Home' },
    { path: '/sfc', element: <Sfc />,icon:<FontAwesomeIcon icon={faChartLine} />,name:'Standard Fare Chart' },
    { path: '/my-expense', element: <MyExpense />,icon:<FontAwesomeIcon icon={faHandHoldingDollar} />,name:'MyExpense' },
    { path: '/dcr', element: <Dcr />,icon:<FontAwesomeIcon icon={faSquarePhoneFlip} />,name:'Direct Call Recording' },
    { path: '/setting', element: <Setting />,icon:<FontAwesomeIcon icon={faGear} />},
    { path: '/logout', element: <Logout />,icon:<FontAwesomeIcon icon={faPowerOff} /> },
]


    // export  {routersConfig,sideBarConfig};
