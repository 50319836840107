import React, { useEffect, useState } from "react";
import SfcFormOne from "./SfcFormOne";
import SfcFormTwo from "./SfcFormTwo";
import SfcFormThree from "./SfcFormThree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchSfcFareAndAllowance } from "../../redux/slice/sfcFareAndAllowance";

const validateSfcForm = ({FromTerritory,ToTerritory,FromCity,ToCity,CategoryId,TransportMode}) => !!FromTerritory && !!ToTerritory && !!FromCity && !!ToCity && !!CategoryId && !!TransportMode

const sfcResponseProcesser = (data) => ({
  OneWayDistance: data.oneWay,
  TwoWayDistance: data.twoWay,
  OneWayFare: data.oneWayFare,
  TwoWayFare: data.twoWayFare,
  DailyAllowance: data.amount_PerKM,
  Specification: data.specification,
  Total: data.totalFare,
})

const compareSfcFareAndAllowance = (previous, present) => {
  return previous.fromTerritory !==  present.FromTerritory ||
  previous.toTerritory !== present.ToTerritory ||
  previous.fromCity !== present.FromCity ||
  previous.toCity !== present.ToCity ||
  previous.categoryId !== present.CategoryId ||
  previous.transportMode !== present.TransportMode
}


const FormContainer = ({ onBack }) => {
  const [isOneWayDisabled,setIsOneWayDisabled] = useState(true);

  const dispatch = useDispatch();
  const { isLoading, data: sfcFareAndAllowance } = useSelector((state) => state.sfcFareAndAllowance)
  const [expenseIdCreationFormData, setExpenseIdCreationFormData] = useState({
    FromTerritory: "",
    ToTerritory: "",
    FromCity: "",
    ToCity: "",
    CategoryId: "",
    TransportMode: "",
  });
  const [activeForm, setActiveForm] = useState(1);

  const onBackHandler = () => {
    if(activeForm === 1) onBack && onBack()
    setActiveForm((prev) => prev <= 1 ? 1 : prev - 1)
  };

  const onNextHandler = () => {
    const validate = validateSfcForm(expenseIdCreationFormData)
    setActiveForm(prev => prev === 3 ? prev : prev === 1 ? validate ? prev + 1 : 1 : prev + 1)
    if(activeForm === 1 && sfcFareAndAllowance === null && validate) {
      dispatch(fetchSfcFareAndAllowance(expenseIdCreationFormData))
    }
    if(sfcFareAndAllowance && validate && compareSfcFareAndAllowance(sfcFareAndAllowance, expenseIdCreationFormData)) {
      dispatch(fetchSfcFareAndAllowance(expenseIdCreationFormData))

    }

  };

  const onChangeHandler = ({ target: { name, value } }) => {
    setExpenseIdCreationFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if(sfcFareAndAllowance) {
      setIsOneWayDisabled(sfcFareAndAllowance.oneWay !== 0);
      setExpenseIdCreationFormData((prev) => ({...prev,...sfcResponseProcesser(sfcFareAndAllowance)}))
    }
  },[sfcFareAndAllowance])

  return (
    <div className="h-lvh w-lvw flex flex-col justify-between">
      <div>
      <div className="p-5">
        <button onClick={onBackHandler}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      </div>
      <div className="flex justify-around mb-5">
        <div className="text-primary absolute top-5 font-semibold">
          Add New
        </div>
      </div>
      <div>
        <div className="max-w-2xl mx-auto w-[80vw] flex items-center">
          <div className={`bg-primary border-2 border-primary text-white min-w-[36px] h-[36px] flex items-center justify-center rounded-full`}>1</div>
          <div
            className={`my-lg:w-32  md:w-80 h-1 bg-${
              activeForm > 1 ? "primary" : "slt-color"
            }`}
          ></div>
          <div className={`${activeForm >= 2 && 'bg-primary'} border-2 border-primary text-white min-w-[36px] h-[36px] flex items-center justify-center rounded-full`}><span>2</span></div>
          <div
            className={`my-lg:w-32 md:w-80 h-1 bg-${
              activeForm > 2 ? "primary" : "slt-color"
            }`}
          ></div>
          <div className={`${activeForm >= 3 && 'bg-primary'} border-2 border-primary text-white min-w-[36px] h-[36px] flex items-center justify-center rounded-full`}><span>3</span></div>
        </div>
      </div>
      <div className="mt-8 mx-3">
        {activeForm === 1 ? (
            <SfcFormOne onChangeHandler={onChangeHandler} formValues={expenseIdCreationFormData} />
        ) : activeForm === 2 ? (
            <SfcFormTwo onChangeHandler={onChangeHandler} formValues={expenseIdCreationFormData} disableOneway={isOneWayDisabled}/>
        ) : activeForm === 3 ? (
            <SfcFormThree onChangeHandler={onChangeHandler} formValues={expenseIdCreationFormData} goToHomePage={onBack}/>
        ) : null}
      </div>
      </div>
      {activeForm === 3 ? null : <div>
        <div className="w-1/1 h-0.5 bg-gray-200"></div>
        <div
          className="flex justify-end p-2"
        >
          <button
          className="w-[171px] bg-primary text-white rounded-full p-3"
          onClick={onNextHandler}
          >
          {activeForm === 3 ? "Add" : "Next"}
          </button>
        </div>
      </div>}
    </div>
  );
};

export default FormContainer;
