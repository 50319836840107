import { configureStore } from '@reduxjs/toolkit'
import cameraReducer from './slice/cameraSlice'
import sfcTravelingExpencesReducer from './slice/sfcTravelingExpences'
import sfcFareAndAllowanceReducer from './slice/sfcFareAndAllowance'
import sfcSaveTravelExpenceReducer from './slice/sfcSaveTravelExpence'
import sfcFormFiltersReducer from './slice/sfcFormFilters'

export const store = configureStore({
  reducer: {
    camera: cameraReducer,
    sfcTravelingExpences: sfcTravelingExpencesReducer,
    sfcFareAndAllowance: sfcFareAndAllowanceReducer,
    sfcSaveTravelExpence: sfcSaveTravelExpenceReducer,
    sfcFormFilters: sfcFormFiltersReducer
  },
})
