import React, { useState } from 'react';
import './fontAwesomeConfig';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { routersConfig } from './router/routersConfig';
import SideBar from './components/sidebar/SideBar';


const App = () => {
  return (
    <Router>
      <SideBar />
      <Routes>
        {routersConfig.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={route.element}
          />
        ))}
      </Routes>
    </Router>
  );
};

export default App;
