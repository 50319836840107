import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEdit, faCrop } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { currentImage } from '../../redux/slice/cameraSlice';
import { useSelector,useDispatch } from 'react-redux';


const CapturedImage = () => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [invoiceName, setInvoiceName] = useState('Name of the Invoice');
  const receiptImage = useSelector((state) => state.camera.image);
  const cropImage = useSelector((state) => state.camera.cropImage);
  const fileImage = useSelector((state) => state.camera.file);
  const imageSrc = cropImage ? cropImage : fileImage ? fileImage : receiptImage;
  dispatch(currentImage(imageSrc))




  const handleEditClick = () => {
    setInvoiceName(invoiceName);
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setInvoiceName(e.target.value);
  };

  const handleSaveClick = () => {
    setInvoiceName(invoiceName);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setInvoiceName(invoiceName);
    setIsEditing(false);
  };

  return (
    <div className="w-full h-full flex flex-col p-4">
      {/* Header */}
      <div className="bg-white shadow-md flex items-center justify-between border-b border-gray-200 mb-6">
        <Link to="/camera">
          <button className="text-gray-500">
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        </Link>
        <div className="text-lg font-semibold flex-grow text-center">
          {isEditing ? (
            <div className="flex items-center">
              <input
                type="text"
                value={invoiceName}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-lg p-1"
              />
              <button
                className="ml-2 bg-blue-500 text-white py-1 px-2 rounded-lg"
                onClick={handleSaveClick}
              >
                Save
              </button>
              <button
                className="ml-2 bg-gray-500 text-white py-1 px-2 rounded-lg"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          ) : (
            <span>{invoiceName}</span>
          )}
        </div>
        <div className="flex items-center space-x-2">
          {!isEditing && (
            <button className="text-gray-500" onClick={handleEditClick}>
              <FontAwesomeIcon icon={faEdit} />
            </button>
          )}
          <Link to="/crop-image">
          <button className="text-gray-500"
          >
            <FontAwesomeIcon icon={faCrop} />
          </button>
          </Link>
        </div>
      </div>

      {/* Image Display */}
      <div className="flex items-center justify-center p-4 " style={{ height: '75vh', widows: '100vw' }}>
        <img
          src={imageSrc}
          // width={300}
          alt="Captured image"
          className="w-full h-full  bg-slate-400 rounded-lg"
        />
      </div>

      {/* Footer Buttons */}
      <div className=" bg-white  border-gray-200 flex justify-between">
        <Link to="/camera">
          <button className="py-4 px-14  bg-white border border-red-400 rounded-lg">
           <p className='text-xl fontSem-bold' style={{color: '#A04D95', fontWeight: '600'}}>Retake</p>
          </button>
        </Link>
        <Link to="/captured-image-details">
          <button className=" text-white  py-4 px-14  rounded-lg" style={{ backgroundColor: '#A04D95' }}>
            <p className='text-white text-xl fontSem-bold'>Process</p>
          </button>
        </Link>
      </div>
    </div>
  )
};

export default CapturedImage;
