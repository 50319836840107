import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseURL = "https://keaexpenseapi.kea-sfe.com/api/ExpenseIdCreation";
// const baseURL = "http://192.168.1.243:1105/api/ExpenseIdCreation";


export const fetchSfcFareAndAllowance = createAsyncThunk(
  "fetchSfcFareAndAllowance",
  async (sfcForm1) => {
    return axios.get(
        `${baseURL}/SFCFareAndAllowance`,
        { params: { EmployeeId:8271, GradeId: 1, EffectiveDate: '2024-08-01',...sfcForm1}}
      );

  }
);


export const sfcFareAndAllowance = createSlice({
  name: "SfcFareAndAllowance",
  initialState: {
    isLoading: false,
    data: null,
    apiError: "",
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSfcFareAndAllowance.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSfcFareAndAllowance.fulfilled, (state, action) => {

      state.isLoading = false;
        state.data = action.payload.data;
        state.apiError = false;
    });
    builder.addCase(fetchSfcFareAndAllowance.rejected, (state, action) => {
      state.isLoading = false;
      state.data = null;
      state.apiError = "Something is Wrong";
    });
  },
});

export default sfcFareAndAllowance.reducer;
