import React from 'react';

const Callplan = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <h1 className="text-3xl font-bold text-center">Welcome To Call Plan Page</h1>
    </div>
  );
}

export default Callplan;