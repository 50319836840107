import React, { useEffect } from "react";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { postSfcSaveTravelExpence } from "../../redux/slice/sfcSaveTravelExpence";



const SfcFormThree = ({ onChangeHandler, formValues, goToHomePage }) => {
  const dispatch = useDispatch();
  const { isLoading, data: sfcSaveTravelExpence} = useSelector((state) => state.sfcSaveTravelExpence);
  useEffect(() => {
    if(sfcSaveTravelExpence) {
      goToHomePage();
    }
  },[sfcSaveTravelExpence]);


  const onAddHandler = () => {
    if(formValues.Remarks) {
      dispatch(postSfcSaveTravelExpence(formValues));
    }
  }

  return (
    <div className="max-w-2xl mx-auto">
      <div className="text-secondary-400 text-fieldset">Remarks</div>
      <div className="w-1/1 h-0.5 bg-gray-200 mt-2"></div>
      <div>
        <input
          onChange={onChangeHandler}
          name="Remarks"
          type="text"
          value={formValues?.Remarks}
          className="w-full border border-gray-300 mt-5 focus:outline-none rounded h-10 p-2"
        />
      </div>
      <div className="mt-4">
        <div className="text-secondary-400 text-fieldset">Specification</div>
        <div className="w-1/1 h-0.5 bg-gray-200 mt-2"></div>
        <div className="mt-5 flex gap-1 w-full">
          <input
            type="text"
            className="border border-gray-300 focus:outline-none rounded grow bg-slt-color h-10"
            disabled={true}
            value={formValues.Specification || ""}
          />
          <span className="ml-2 text-xl">
            <button onClick={() => onAddHandler()}>
              <FontAwesomeIcon
                icon={faSquarePlus}
                color={"#A04D95"}
                size="2xl"

              />
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};
export default SfcFormThree;
