import React, { useState } from 'react';

const JokeComponent = () => {
  const [joke, setJoke] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchJoke = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch('https://v2.jokeapi.dev/joke/Any');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setJoke(data.type === 'single' ? data.joke : `${data.setup} - ${data.delivery}`);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4 pt-8 flex flex-col justify-center">
      <button
        onClick={fetchJoke}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition"
      >
        Clicked me
      </button>
      {loading && <p className="mt-4 text-gray-600">Loading...</p>}
      {error && <p className="mt-4 text-red-500">{error}</p>}
      {joke &&
      <div className='mt-4 bg-gray-300 p-4 rounded' >
              <h6 className="mt-4 text-xl text-purple-500 bold">{joke}</h6>
      </div>}
    </div>
  );
};

export default JokeComponent;
