import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCamera, faCalendarAlt,faBell } from '@fortawesome/free-solid-svg-icons';
// import CameraComponent from '../../pages/camera/CameraOption';
import { Link } from 'react-router-dom';

const Footer = () => {
  // const [showCamera, setShowCamera] = useState(false);

  // const handleCameraClick = () => {
  //   setIsCameraOpen(true);
  // };

  return (
    <>
      {/* {showCamera && <CameraComponent />} */}
      <footer className="fixed bottom-0 w-full bg-red-50 text-white">
        <div className="flex justify-around items-center h-16">
          <button className="footer-button">
            <FontAwesomeIcon icon={faHome} size="2x"  color='gray'/>
          </button>
          <Link to="/camera">
            <button className="footer-button">
              <FontAwesomeIcon icon={faCamera} size="2x"  color='gray'/>
            </button>
            </Link>
          {/* <button className="footer-button" onClick={handleCameraClick}>
            <FontAwesomeIcon icon={faCamera} size="2x" />
          </button> */}
          <button className="footer-button">
            <FontAwesomeIcon icon={faBell} size="2x" color='gray' />
          </button>
        </div>
      </footer>
    </>
  );
};

// export default Footer;

// const Footer = () => {
//   return (
//     <footer className="fixed bottom-0 w-full bg-gray-800 text-white">
//       <div className="flex justify-around items-center h-16">
//         <button className="flex flex-col items-center">
//           <FontAwesomeIcon icon={faHome} size="2x" />
//           {/* <span className="text-sm">Home</span> */}
//         </button>
//         <button className="flex flex-col items-center">
//           <FontAwesomeIcon icon={faCamera} size="2x" />
//           {/* <span className="text-sm">Camera</span> */}
//         </button>
//         <button className="flex flex-col items-center">
//           <FontAwesomeIcon icon={faCalendarAlt} size="2x" />
//           {/* <span className="text-sm">Calendar</span> */}
//         </button>
//       </div>
//     </footer>
//   );
// };
// <<<<<<<  f029a39e-e4d3-414f-8fe6-765e77bc1a9d  >>>>>>>

export default Footer;
