import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faFilter,
  faPlane,
  faSquarePlus,
} from "@fortawesome/free-solid-svg-icons";
import CardContainer from "./CardContainer";
import Btn from "./Btn";
import FormContainer from "./FormContainer";
import { useDispatch, useSelector } from "react-redux";
import { fetchSfcTravelingExpences } from "../../redux/slice/sfcTravelingExpences";
import { fetchSfcFormFilters } from "../../redux/slice/sfcFormFilters";

const Sfc = () => {
  const [stepOne, setStepOne] = useState(false);
  const [isSuccessfullySaved, seIsSuccessfullySaved] = useState(false);
  const { isLoading, transport } = useSelector(
    (state) => state.sfcFormFilters
  );
  const { data: sfcSaveTravelExpence = {}} = useSelector(
    (state) => state.sfcSaveTravelExpence
  );
  const { data: sfcTravelingExpences } = useSelector((state) => state.sfcTravelingExpences);
  const [toggle, setToggle] = useState("");
  const dispatch = useDispatch();

  const onClickHandler = (val) => {
    if (toggle) {
      setToggle((prev) => (prev === val ? "" : val));
      // setDisplayData(data);
    } else {
      // setDisplayData(() => [
      //   ...data.filter((value) => value.transport === val),
      // ]);
      setToggle(val);
    }
  };

  useEffect(() => {
    if (sfcSaveTravelExpence) {
      setTimeout(() => {
        seIsSuccessfullySaved(false);
         dispatch(fetchSfcTravelingExpences());
      }, 2000);
      seIsSuccessfullySaved(true);
    }
  }, [sfcSaveTravelExpence]);

  useEffect(() => {
    dispatch(fetchSfcFormFilters())
    dispatch(fetchSfcTravelingExpences())
  }, []);


  return isSuccessfullySaved ? (
    <div className="w-lvw h-lvh flex flex-col justify-center items-center fieldset">
      <div className="text-[4rem] text-primary m-5">
        <FontAwesomeIcon icon={faCircleCheck} />
      </div>
      <p className="text-primary text-fieldset">Added</p>
    </div>
  ) : (
    <>
      {!stepOne && (
        <div className="h-lvh w-lvw flex flex-col justify-between">
          <div>
          <div className="flex justify-end m-2 px-2">
            <button
              onClick={() => {
                setStepOne((prev) => !prev);
              }}
            >
              <FontAwesomeIcon
                icon={faSquarePlus}
                color={"#A04D95"}
                size={"xl"}
              />
            </button>
          </div>
          <div className="p-3 flex gap-6 items-center mb-2 max-w-full">
            <div className="grow overflow-scroll">
              <div className="flex items-center gap-3">
              {transport && transport.length &&
              transport.map((val, index) => {
                return (
                    <Btn
                    key={index}
                      text={val.
                        transport_name
                        }
                      onClick={onClickHandler.bind(this, val.
                        transport_name
                        )}
                        index={index}
                      isSelected={toggle === val.
                        transport_name
                        }
                    />
                );
              })}
              </div>
            </div>
            <div>
              <FontAwesomeIcon icon={faFilter} color={"#A04D95"}/>
            </div>
          </div>
          <div className="flex flex-col bg-gray-300 " style={{ rowGap: '2px'}}>
            {sfcTravelingExpences &&
              sfcTravelingExpences?.map((val, index) => {
                return (
                  !toggle || val.transporT_TYPE === toggle ? <CardContainer
                    status={val?.status}
                    key={index}
                    setDisplayData={sfcSaveTravelExpence}
                    val={val}
                  /> : null
                );
              })}
          </div>
          </div>
          <div className="flex justify-end items-center m-5">
            <button className="w-full bg-primary text-white rounded-full p-3">
              Submit
            </button>
          </div>
        </div>
      )}
      {stepOne && <FormContainer onBack={() => setStepOne(false)} />}
    </>)
};

export default Sfc;
